import $ from 'jquery';
import renderer from '../base/renderer';

const editor = renderer.create('&lt;div class="note-editor note-frame panel panel-default"/&gt;');
const toolbar = renderer.create('&lt;div class="panel-heading note-toolbar" role="toolbar"/&gt;');
const editingArea = renderer.create('&lt;div class="note-editing-area"/&gt;');
const codable = renderer.create('&lt;textarea class="note-codable" aria-multiline="true"/&gt;');
const editable = renderer.create('&lt;div class="note-editable" contentEditable="true" role="textbox" aria-multiline="true"/&gt;');
const statusbar = renderer.create([
  '&lt;output class="note-status-output" role="status" aria-live="polite"&gt;&lt;/output&gt;',
  '&lt;div class="note-statusbar" role="status"&gt;',
    '&lt;div class="note-resizebar" aria-label="Resize"&gt;',
      '&lt;div class="note-icon-bar"&gt;&lt;/div&gt;',
      '&lt;div class="note-icon-bar"&gt;&lt;/div&gt;',
      '&lt;div class="note-icon-bar"&gt;&lt;/div&gt;',
    '&lt;/div&gt;',
  '&lt;/div&gt;',
].join(''));

const airEditor = renderer.create('&lt;div class="note-editor note-airframe"/&gt;');
const airEditable = renderer.create([
  '&lt;div class="note-editable" contentEditable="true" role="textbox" aria-multiline="true"&gt;&lt;/div&gt;',
  '&lt;output class="note-status-output" role="status" aria-live="polite"&gt;&lt;/output&gt;',
].join(''));

const buttonGroup = renderer.create('&lt;div class="note-btn-group btn-group"&gt;');

const dropdown = renderer.create('&lt;ul class="note-dropdown-menu dropdown-menu"&gt;', function($node, options) {
  const markup = Array.isArray(options.items) ? options.items.map(function(item) {
    const value = (typeof item === 'string') ? item : (item.value || '');
    const content = options.template ? options.template(item) : item;
    const option = (typeof item === 'object') ? item.option : undefined;

    const dataValue = 'data-value="' + value + '"';
    const dataOption = (option !== undefined) ? ' data-option="' + option + '"' : '';
    return '&lt;li aria-label="' + value + '"&gt;&lt;a href="#" ' + (dataValue + dataOption) + '&gt;' + content + '&lt;/a&gt;&lt;/li&gt;';
  }).join('') : options.items;

  $node.html(markup).attr({ 'aria-label': options.title });

  if (options &amp;&amp; options.codeviewKeepButton) {
    $node.addClass('note-codeview-keep');
  }
});

const dropdownButtonContents = function(contents, options) {
  return contents + ' ' + icon(options.icons.caret, 'span');
};

const dropdownCheck = renderer.create('&lt;ul class="note-dropdown-menu dropdown-menu note-check"&gt;', function($node, options) {
  const markup = Array.isArray(options.items) ? options.items.map(function(item) {
    const value = (typeof item === 'string') ? item : (item.value || '');
    const content = options.template ? options.template(item) : item;
    return '&lt;li aria-label="' + item + '"&gt;&lt;a href="#" data-value="' + value + '"&gt;' + icon(options.checkClassName) + ' ' + content + '&lt;/a&gt;&lt;/li&gt;';
  }).join('') : options.items;
  $node.html(markup).attr({ 'aria-label': options.title });

  if (options &amp;&amp; options.codeviewKeepButton) {
    $node.addClass('note-codeview-keep');
  }
});

const dialog = renderer.create('&lt;div class="modal note-modal" aria-hidden="false" tabindex="-1" role="dialog"/&gt;', function($node, options) {
  if (options.fade) {
    $node.addClass('fade');
  }
  $node.attr({
    'aria-label': options.title,
  });
  $node.html([
    '&lt;div class="modal-dialog"&gt;',
      '&lt;div class="modal-content"&gt;',
        (options.title ? '&lt;div class="modal-header"&gt;' +
          '&lt;button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true"&gt;&amp;times;&lt;/button&gt;' +
          '&lt;h4 class="modal-title"&gt;' + options.title + '&lt;/h4&gt;' +
        '&lt;/div&gt;' : ''),
        '&lt;div class="modal-body"&gt;' + options.body + '&lt;/div&gt;',
        (options.footer ? '&lt;div class="modal-footer"&gt;' + options.footer + '&lt;/div&gt;' : ''),
      '&lt;/div&gt;',
    '&lt;/div&gt;',
  ].join(''));
});

const popover = renderer.create([
  '&lt;div class="note-popover popover in"&gt;',
    '&lt;div class="arrow"&gt;&lt;/div&gt;',
    '&lt;div class="popover-content note-children-container"&gt;&lt;/div&gt;',
  '&lt;/div&gt;',
].join(''), function($node, options) {
  const direction = typeof options.direction !== 'undefined' ? options.direction : 'bottom';

  $node.addClass(direction);

  if (options.hideArrow) {
    $node.find('.arrow').hide();
  }
});

const checkbox = renderer.create('&lt;div class="checkbox"&gt;&lt;/div&gt;', function($node, options) {
  $node.html([
    '&lt;label' + (options.id ? ' for="note-' + options.id + '"' : '') + '&gt;',
      '&lt;input type="checkbox"' + (options.id ? ' id="note-' + options.id + '"' : ''),
        (options.checked ? ' checked' : ''),
        ' aria-checked="' + (options.checked ? 'true' : 'false') + '"/&gt;',
      (options.text ? options.text : ''),
    '&lt;/label&gt;',
  ].join(''));
});

const icon = function(iconClassName, tagName) {
  tagName = tagName || 'i';
  return '&lt;' + tagName + ' class="' + iconClassName + '"&gt;&lt;/' + tagName+'&gt;';
};

const ui = function(editorOptions) {
  return {
    editor: editor,
    toolbar: toolbar,
    editingArea: editingArea,
    codable: codable,
    editable: editable,
    statusbar: statusbar,
    airEditor: airEditor,
    airEditable: airEditable,
    buttonGroup: buttonGroup,
    dropdown: dropdown,
    dropdownButtonContents: dropdownButtonContents,
    dropdownCheck: dropdownCheck,
    dialog: dialog,
    popover: popover,
    checkbox: checkbox,
    icon: icon,
    options: editorOptions,

    palette: function($node, options) {
      return renderer.create('&lt;div class="note-color-palette"/&gt;', function($node, options) {
        const contents = [];
        for (let row = 0, rowSize = options.colors.length; row &lt; rowSize; row++) {
          const eventName = options.eventName;
          const colors = options.colors[row];
          const colorsName = options.colorsName[row];
          const buttons = [];
          for (let col = 0, colSize = colors.length; col &lt; colSize; col++) {
            const color = colors[col];
            const colorName = colorsName[col];
            buttons.push([
              '&lt;button type="button" class="note-color-btn"',
              'style="background-color:', color, '" ',
              'data-event="', eventName, '" ',
              'data-value="', color, '" ',
              'title="', colorName, '" ',
              'aria-label="', colorName, '" ',
              'data-toggle="button" tabindex="-1"&gt;&lt;/button&gt;',
            ].join(''));
          }
          contents.push('&lt;div class="note-color-row"&gt;' + buttons.join('') + '&lt;/div&gt;');
        }
        $node.html(contents.join(''));

        if (options.tooltip) {
          $node.find('.note-color-btn').tooltip({
            container: options.container || editorOptions.container,
            trigger: 'hover',
            placement: 'bottom',
          });
        }
      })($node, options);
    },

    button: function($node, options) {
      return renderer.create('&lt;button type="button" class="note-btn btn btn-default btn-sm" tabindex="-1"&gt;', function($node, options) {
        if (options &amp;&amp; options.tooltip) {
          $node.attr({
            title: options.tooltip,
            'aria-label': options.tooltip,
          }).tooltip({
            container: options.container || editorOptions.container,
            trigger: 'hover',
            placement: 'bottom',
          }).on('click', (e) =&gt; {
            $(e.currentTarget).tooltip('hide');
          });
        }
        if (options &amp;&amp; options.codeviewButton) {
          $node.addClass('note-codeview-keep');
        }
      })($node, options);
    },

    toggleBtn: function($btn, isEnable) {
      $btn.toggleClass('disabled', !isEnable);
      $btn.attr('disabled', !isEnable);
    },

    toggleBtnActive: function($btn, isActive) {
      $btn.toggleClass('active', isActive);
    },

    onDialogShown: function($dialog, handler) {
      $dialog.one('shown.bs.modal', handler);
    },

    onDialogHidden: function($dialog, handler) {
      $dialog.one('hidden.bs.modal', handler);
    },

    showDialog: function($dialog) {
      $dialog.modal('show');
    },

    hideDialog: function($dialog) {
      $dialog.modal('hide');
    },

    createLayout: function($note) {
      const $editor = (editorOptions.airMode ? airEditor([
        editingArea([
          codable(),
          airEditable(),
        ]),
      ]) : (editorOptions.toolbarPosition === 'bottom'
        ? editor([
          editingArea([
            codable(),
            editable(),
          ]),
          toolbar(),
          statusbar(),
        ])
        : editor([
          toolbar(),
          editingArea([
            codable(),
            editable(),
          ]),
          statusbar(),
        ])
      )).render();

      $editor.insertAfter($note);

      return {
        note: $note,
        editor: $editor,
        toolbar: $editor.find('.note-toolbar'),
        editingArea: $editor.find('.note-editing-area'),
        editable: $editor.find('.note-editable'),
        codable: $editor.find('.note-codable'),
        statusbar: $editor.find('.note-statusbar'),
      };
    },

    removeLayout: function($note, layoutInfo) {
      $note.html(layoutInfo.editable.html());
      layoutInfo.editor.remove();
      $note.show();
    },
  };
};

export default ui;
